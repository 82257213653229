<template>
  <!-- <div class="home" :style="{backgroundImage:`url(${img})`}"></div> -->
  <div class="home">
    <div class="pic" :style="{ backgroundImage: `url(${img})` }"></div>
    <div class="biaoyu">
      <!-- 设置logo -->
      <div class="logo"></div>
      <!-- 设置标语 -->
      <div class="message">Enjoy the Rock Climbing<br />with me</div>
    </div>
    <!-- 登录注册按钮 -->
    <div class="log">
      <van-button @click="goLogin" round type="info" class="signup"
        >登录</van-button
      >
      <van-button @click="goMain" round type="primary" class="signup"
        >游客入口</van-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: require("../assets/upload/fengjing.jpg"),
    };
  },
  methods: {
    goLogin() {
      this.$router.push("/Login");
    },
    goMain() {
      this.$router.push("/main");
    },
  },

  mounted() {
    //这块代码逻辑有问题
    if (localStorage.getItem("token")) {
      this.$router.push("/main");
    } else {
      localStorage.setItem("token", "");
      localStorage.setItem("userInfo", JSON.stringify(""));
      this.$store.commit("SET_TOKEN", "");
      this.$store.commit("SET_USERINFO", JSON.stringify(""));
    }
  },
};
</script>

<style>
.home {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100vh;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.5;
  width: 100%;
  background-size: cover;
  /* background-size: 100%; */
}

.pic {
  position: absolute;
  background-size: 100%;
  height: 100vh;
  width: 600px;
  max-width: 600px;
  min-width: 400px;
  z-index: -999;
  overflow: hidden;
}

.biaoyu {
  width: 100%;
  position: relative;
  margin: 0 auto;
  margin-top: 70px;
  overflow: hidden;
  z-index: -1;
  box-sizing: border-box;
}
.logo {
  position: relative;
  /* top: 50px; */
  width: 100%;
  z-index: -1;
}
.logo::before {
  margin: 0 auto;
  content: "";
  display: block;
  width: 9rem;
  height: 9rem;
  background: url("../assets/images/logo1.png") no-repeat;
  background-size: cover;
  border: solid 1px black;
  border-radius: 15px;
}
.message {
  margin-top: 50px;
  width: 100%;
  text-align: center;
  position: relative;
  font-size: 1.7rem;
  font-weight: bold;
  color: white;
}
.log {

  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}

/* 注册按钮 */
.signup {
  box-sizing: border-box;
  margin: 10px auto;
  width: 14rem;
  border-radius: 15px;
  padding: 10px;
  font-weight: bold;
  font-size: 17px;
  letter-spacing: 5px;
  z-index: 10;
}

/* 登录按钮 */
.signin {
  box-sizing: border-box;
  margin: 10px auto;
  width: 14rem;
  border-radius: 15px;
  padding: 10px;
  font-weight: bold;
  font-size: 17px;
  letter-spacing: 5px;
  z-index: 10;
}
</style>